<template>
  <v-container>
  <v-skeleton-loader v-if="!escuelaTienePermiso" type="list-item-two-line@5"></v-skeleton-loader>
    <v-card v-else rounded="lg">
      <v-toolbar dense color="secondary" dark flat class="text-h6">
        <v-btn icon @click="regresarCurso()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <span>{{ nombreModulo }}</span>
        <v-spacer></v-spacer>
        <v-menu v-if="permisoEscritura && !vistaSeguimiento" offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="menuItem in menuAplicacion"
              :key="menuItem.accion"
              @click="menuAction(menuItem.accion)"
            >
              <v-list-item-title><font :color="menuItem.color">{{ menuItem.nombre }}</font></v-list-item-title>
              <v-list-item-icon
                ><v-icon :color="menuItem.color" small>{{ menuItem.icono }}</v-icon></v-list-item-icon
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>

      <loading-aplicacion v-if="loading"></loading-aplicacion>
      <contenido-aplicacion
        v-else-if="aplicacion"
        :aplicacion="aplicacion"
        :resultados="resultados"
        :permisoEscritura="permisoEscritura"
        :siguiente="siguiente"
        :anterior="anterior"
        :calificacion="calificacion"
        :archivado="archivado"
        @criterioModificado="criterioModificado"
        @comenzarAplicacion="comenzarAplicacion()"
        @continuarAplicacion="continuarAplicacion()"
        @cambiarAplicacion="cambiarAplicacion"
      ></contenido-aplicacion>

      <v-container v-if="esCampus || vistaSeguimiento">
        <calificaciones-aplicacion
          v-if="permisoEscritura || vistaSeguimiento || (!sesionAlumno && archivado)"
          :idCurso="idCurso"
          :tipoCurso="tipoCurso"
          :idModulo="idModulo"
          :idAplicacion="idAplicacion"
        ></calificaciones-aplicacion>
      </v-container>
    </v-card>

    <modificacion-dialog
      v-if="mostrarModificacion"
      :mostrar="mostrarModificacion"
      :aplicacion="aplicacion"
      :aModificar="['nombre']"
      @cancelar="mostrarModificacion = false"
      @aplicacionGuardada="nombreModificado"
    ></modificacion-dialog>

    <seleccion-examen
      v-if="mostrarSeleccionExamen"
      :mostrar="mostrarSeleccionExamen"
      :examenes="aplicacion.examenes"
      @cancelar="mostrarSeleccionExamen = false"
      @examenSeleccionado="examenSeleccionado"
    ></seleccion-examen>

    <eliminar-aplicacion
      v-if="eliminarAplicacion"
      :mostrar="eliminarAplicacion"
      :tipoCurso="tipoCurso"
      :idCurso="idCurso"
      :idModulo="idModulo"
      :aplicacion="aplicacion"
      @cancelar="eliminarAplicacion = false"
      @aplicacionEliminada="aplicacionEliminada"
    ></eliminar-aplicacion>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import {
  obtenerAplicacion,
  comenzarAplicacion,
} from "./examenAplicacion.service";

import actividadLoading from "../actividades/actividadLoading.vue";
import { permisoMenuEscuela } from '../../../helpers/utilerias';

export default {
  components: {
    "loading-aplicacion": actividadLoading,
    "contenido-aplicacion": () => import("./contenidoAplicacion.vue"),
    "modificacion-dialog": () => import("./modificacionAplicacion.vue"),
    "seleccion-examen": () => import("./seleccionExamen.vue"),
    "calificaciones-aplicacion": () => import("./calificacionesAplicacion.vue"),
    "eliminar-aplicacion": () => import("./eliminarExamenAplicacion.vue"),
  },

  data: () => ({
    loading: true,
    nombreModulo: "Cargando aplicación de exámenes...",
    aplicacion: null,
    siguiente: null,
    anterior: null,
    resultados: null,
    calificacion: null,
    permisoEscritura: false,
    menuAplicacion: [
      {
        nombre: "Modificar nombre",
        accion: "modificarNombre",
        icono: "mdi-pencil",
      },
      {
        nombre: "Eliminar aplicación",
        accion: "eliminarAplicacion",
        icono: "mdi-trash-can-outline",
        color:"#D32F2F"
      },
    ],
    mostrarModificacion: false,
    mostrarSeleccionExamen: false,
    escuelaTienePermiso:false,
    eliminarAplicacion: false,
    archivado: false,
    sesionAlumno: false
  }),

  computed: {
    ...mapGetters(["sessionToken", "app", "menu", "vistaSeguimiento"]),

    idCurso() {
      return this.$route.params.idCurso;
    },

    idModulo() {
      return this.$route.params.idModulo;
    },

    tipoCurso() {
      return this.$route.params.tipoCurso;
    },

    tipoModulo() {
      return this.$route.params.tipoModulo;
    },

    idAplicacion() {
      return this.$route.params.idAplicacion;
    },

    esCampus() {
      return this.app === "campus";
    },

    resultadoSinTerminar() {
      if (this.resultados && this.resultados.length)
        return this.resultados.find((e) => !e.terminado);
      return null;
    },
  },

  watch: {
    sessionToken(value) {
      if (value){
        if (!permisoMenuEscuela("Cursos")) this.$router.push("/dashboard");
        else {this.escuelaTienePermiso=true; this.obtenerAplicacion();}
      } 
    },

    $route() {
      if (!permisoMenuEscuela("Cursos")) this.$router.push("/dashboard");
      else {
        this.escuelaTienePermiso=true;
        this.obtenerAplicacion();
      }
    },
  },

  mounted() {
    if (this.sessionToken){
      if (!permisoMenuEscuela("Cursos")) this.$router.push("/dashboard");
      else {
        this.escuelaTienePermiso=true;
        this.obtenerAplicacion();
        }
    } 
      
  },

  methods: {
    regresarCurso() {
      this.$router.push(
        `/curso/${this.tipoCurso}/${this.idCurso}/tipo/modulo/${this.idModulo}`
      );
    },

    menuAction(accion) {
      switch (accion) {
        case "modificarNombre":
          this.mostrarModificacion = true;
          break;
        case "eliminarAplicacion":
          this.eliminarAplicacion = true;
          break;
      }
    },

    async obtenerAplicacion() {
      this.loading = true;

      try {
        const serverResponse = await obtenerAplicacion({
          idCurso: this.idCurso,
          idModulo: this.idModulo,
          idAplicacion: this.idAplicacion,
          tipoCurso: this.tipoCurso,
        });
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          this.regresarCurso();
        } else {
          if (serverResponse.resultados)
            this.resultados = serverResponse.resultados;
          if (serverResponse.calificacion)
            this.calificacion = serverResponse.calificacion;
          this.aplicacion = serverResponse.aplicacion;
          this.nombreModulo = serverResponse.aplicacion.cursoModulo.nombre;
          this.permisoEscritura = serverResponse.permisoEscritura;
          this.siguiente = serverResponse.siguiente;
          this.anterior = serverResponse.anterior;
          this.archivado = serverResponse.archivado;
          if (this.tipoCurso === "campus"){
            if (serverResponse.seguimiento == false) {
              this.sesionAlumno = serverResponse.sesionAlumno;
              this.$store.dispatch("setSesionCursoAlumno",serverResponse.sesionAlumno);
            }
            if (serverResponse.seguimiento == true) this.$store.dispatch("setVistaSeguimiento",serverResponse.seguimiento);
          }
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
        this.regresarCurso();
      }
    },

    nombreModificado(aplicacion) {
      this.aplicacion.nombre = aplicacion.nombre;
      this.mostrarModificacion = false;
    },
    criterioModificado(data) {
      this.aplicacion.criterio = data.criterio;
      this.aplicacion.puntos = data.puntosActividad;
      this.aplicacion.ponderacion = -1;
    },

    comenzarAplicacion() {
      if (this.aplicacion.examenAleatorio) this.crearResultado();
      else this.mostrarSeleccionExamen = true;
    },

    examenSeleccionado(examen) {
      this.crearResultado({ idExamen: examen._id });
      this.mostrarSeleccionExamen = false;
    },

    async crearResultado(body = {}) {
      this.loading = true;

      try {
        const serverResponse = await comenzarAplicacion({
          idCurso: this.idCurso,
          idModulo: this.idModulo,
          idAplicacion: this.idAplicacion,
          data: body,
        });
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else
          this.$router.push({
            name: "PresentarExamen",
            params: {
              idResultado: serverResponse.resultado._id,
            },
          });
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    continuarAplicacion() {
      this.$router.push({
        name: "SeccionPresentarExamen",
        params: {
          idResultado: this.resultadoSinTerminar._id,
          idExamen: this.resultadoSinTerminar.examenHistorico,
          idSeccion: this.resultadoSinTerminar.seccion,
        },
      });
    },

    cambiarAplicacion(idAplicacion) {
      this.$router.push({
        name: this.$route.name,
        params: {
          idAplicacion,
        },
      });
    },

    aplicacionEliminada() {
      this.$router.push(`/curso/${this.tipoCurso}/${this.idCurso}/tipo/${this.tipoModulo}/${this.idModulo}`);
    },
  },
};
</script>
