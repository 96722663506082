import {
  getServerRequest,
  putServerRequest,
  patchServerRequest,
  deleteServerRequest,
} from "../../../services/serverRequest.service";
import { store } from "@/store/store";
const httpURL = store.getters.httpURL;

export async function obtenerAplicacion({ idCurso, idModulo, idAplicacion, tipoCurso }) {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/curso/examenAplicacion/${tipoCurso}/${idCurso}/${idModulo}/${idAplicacion}/`;
  return await getServerRequest(url, config);
}

export async function obtenerResultadosAplicacion({ idCurso, idModulo, idAplicacion, tipoCurso }) {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/curso/examenAplicacion/${tipoCurso}/${idCurso}/${idModulo}/${idAplicacion}/resultados`;
  return await getServerRequest(url, config);
}

export async function actualizarAplicacion({ idCurso, idModulo, idAplicacion, data }) {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/curso/examenAplicacion/${idCurso}/${idModulo}/${idAplicacion}`;
  return await patchServerRequest(url, data, config)
}

export async function agregarExamenAAplicacion({ idCurso, idModulo, idAplicacion, data }) {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/curso/examenAplicacion/${idCurso}/${idModulo}/${idAplicacion}/examen`;
  return await putServerRequest(url, data, config)
}

export async function quitarExamenDeAplicacion({ idCurso, idExamen, idAplicacion }) {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/curso/examenAplicacion/${idCurso}/${idAplicacion}/examen/${idExamen}`;
  return await deleteServerRequest(url, config);
}

export async function comenzarAplicacion({ idCurso, idModulo, idAplicacion, data }) {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/curso/examenAplicacion/${idCurso}/${idModulo}/${idAplicacion}/comenzar`;
  return await putServerRequest(url, data, config)
}

export async function eliminarExamenAplicacion({tipoCurso, idCurso, idModulo, idAplicacion }) {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/curso/examenAplicacion/${tipoCurso}/${idCurso}/${idModulo}/${idAplicacion}`;
  return await deleteServerRequest(url, config);
}